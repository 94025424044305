import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-plugin-transition-link";

const StatesWeServe = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      teamMembers: allSanityStates(sort: { fields: [title], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={` ${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mx-auto mb-8 max-w-[640px] text-center md:mb-16">
          <HeadingTag>The States We Serve</HeadingTag>
          <p>
            We've been helping customers in several states afford the home of
            their dreams, and we love what we do. Here’s where we’re licensed.
          </p>
        </header>

        <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
          {data.teamMembers.edges.map(({ node }, i) => (
            <Link
              to={`/${node.slug.current}/`}
              className="flex h-[3.25rem] items-center justify-center rounded-[5.625rem] bg-primary-500 font-bold text-primary-50 no-underline hover:bg-primary-500/80 hover:text-primary-50 md:h-[4.5rem] md:text-lg"
              key={i}
            >
              {node.title}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatesWeServe;
