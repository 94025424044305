import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="About Right Start Mortgage | Full-Service Mortgage Lender"
        description="As a family-owned and operated, full-service mortgage lender, Right Start believes in offering a personalized experience from start to finish. Contact us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/10.0 About/1.0 about-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>About Us</h1>
              <p>
                Right Start Mortgage, founded in 1989, is a company that's
                dedicated to the individual. As a family-owned and operated,
                full-service mortgage lender, we believe in offering a
                personalized experience from start to finish. That personal
                touch and our focus on education, communication, and complete
                transparency set us apart.
              </p>
              <ButtonSolid
                modal="modal-contact"
                text="Contact Us"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <ValueProps />

      <section className="relative mb-20 bg-primary-50 py-48 md:mb-32 md:py-48">
        <ParallaxProvider>
          <div className="absolute left-[5vw] -top-8 md:-top-7">
            <Parallax speed={-5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 146.png"
                  width={144}
                  className="w-[79px] md:w-[134px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-[45vw] top-28 md:top-28 md:left-[20vw]">
            <Parallax translateX={["-40px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 145.png"
                  width={144}
                  className="w-[51px] md:w-[87px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute -left-8 top-32 md:top-auto md:left-[2vw] md:bottom-20">
            <Parallax translateX={["60px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 147.png"
                  width={144}
                  className="w-[69px] md:w-[95px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-5 bottom-4 z-10 md:left-[35vw] md:-bottom-4">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 148.png"
                  width={144}
                  className="w-[73px] md:w-[124px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute right-[15vw] bottom-0 md:-bottom-10 md:right-[25vw]">
            <Parallax speed={-7}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 142.png"
                  width={144}
                  className="w-[56px] md:w-[95px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute bottom-28 -right-2 md:bottom-28 md:right-[4.5vw]">
            <Parallax translateX={["40px", "0px"]}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 143.png"
                  width={144}
                  className="w-[71px] md:w-[120px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute left-[40vw] bottom-24 md:bottom-auto md:left-auto md:right-[19vw] md:top-36">
            <Parallax translateX={["-50px", "0px"]}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 149.png"
                  width={144}
                  className="w-[48px] md:w-[82px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute top-22 right-0 md:top-12 md:right-[8vw]">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-primary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 144.png"
                  width={144}
                  className="w-[56px] md:w-[116px]"
                />
              </div>
            </Parallax>
          </div>

          <div className="absolute top-22 right-0 md:-top-2 md:right-[42vw]">
            <Parallax speed={5}>
              <div className="rounded-full border-2 border-secondary-500 p-1.5 md:p-2.5">
                <StaticImage
                  src="../images/10.0 About/Team Bubbles/Group 150.png"
                  width={144}
                  className="w-[51px] md:w-[87px]"
                />
              </div>
            </Parallax>
          </div>
        </ParallaxProvider>

        <div className="container relative">
          <header className="mx-auto max-w-[640px] text-center">
            <h2>Meet the Team</h2>
            <p>
              We’re a group of mortgage professionals dedicated to making your
              loan process a smooth one. Our goal is to match you with the right
              loan, find you the best interest rate, and help you reach your
              objective, whether you want to own a home or refinance an existing
              loan.
            </p>
            <ButtonSolid href="/meet-the-team/" text="Meet the Team" />
          </header>
        </div>
      </section>

      {/* <section className="mb-20 md:mb-32">
        <div className="container"></div>
      </section> */}

      <Testimonials />
      <StatesWeServe />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Company.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Company.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
